import { getCountry, getIsoLocale } from "./link-utils/get-sb-locale"

export async function loadInParallel(f1: Promise<any>, f2: Promise<any>): Promise<[any, any]> {
  const promises = [f1, f2]

  const parallelResult = await Promise.allSettled(promises)
  let result1: any
  if (parallelResult[0].status === "fulfilled") {
    result1 = parallelResult[0].value
  }
  let result2: any
  if (parallelResult[1].status === "fulfilled") {
    result2 = parallelResult[1].value
  }
  return [result1, result2]
}

export function formatPrice(price?: number, currency?: string): string {
  if (price !== undefined && currency) {
    return new Intl.NumberFormat(getIsoLocale(), {
      style: "currency",
      currency: currency,
    }).format(price)
  }
  return ""
}

export function getLastElement(subject: string, separator: string): string {
  return subject.split(separator).slice(-1)[0]
}

export function showContentCountryDependent(countriesToExclude: any) {
  const currentCountry = getCountry()
  const currentCountryExcluded: boolean = countriesToExclude?.includes(currentCountry) || false
  return !currentCountryExcluded
}
