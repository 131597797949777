import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/node_modules/.pnpm/@next+third-parties@14.2.7_next@14.2.7_@babel+core@7.24.7_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/node_modules/.pnpm/@next+third-parties@14.2.7_next@14.2.7_@babel+core@7.24.7_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/node_modules/.pnpm/@next+third-parties@14.2.7_next@14.2.7_@babel+core@7.24.7_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["Root","displayName"] */ "/opt/ub-storefront-next/source/node_modules/.pnpm/@radix-ui+react-label@2.1.0_@types+react-dom@18.3.0_@types+react@18.3.5_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@radix-ui/react-label/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/node_modules/.pnpm/@storyblok+react@3.0.14_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@storyblok/react/dist/bridge-loader.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/node_modules/.pnpm/@storyblok+react@3.0.14_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@storyblok/react/dist/story.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/node_modules/.pnpm/next-intl@3.19.0_next@14.2.7_@babel+core@7.24.7_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/node_modules/.pnpm/next@14.2.7_@babel+core@7.24.7_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/node_modules/.pnpm/next@14.2.7_@babel+core@7.24.7_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/node_modules/.pnpm/next@14.2.7_@babel+core@7.24.7_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/node_modules/.pnpm/next@14.2.7_@babel+core@7.24.7_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/node_modules/.pnpm/next@14.2.7_@babel+core@7.24.7_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/node_modules/.pnpm/next@14.2.7_@babel+core@7.24.7_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Open_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\",\"800\"]}],\"variableName\":\"font\"}");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/layout/AddToBasketDialogClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/layout/ExitDialogClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/layout/InfoBarClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/layout/InitialCustomerTypeDialogClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/layout/Loading.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/product/ProductCardAddToBasket.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/product/ProductCardDiscount.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/product/ProductCardPrice.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/storyblok/UbCatalogueOrderForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/storyblok/UbContactUsGenericForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UbGridShowMoreButton"] */ "/opt/ub-storefront-next/source/storefront/src/components/storyblok/UbGridShowMoreButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/storyblok/UBHeaderClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/storyblok/UbLoginForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/storyblok/UBProductImage.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/ui/components/accordion.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/ui/components/carousel.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/ui/components/checkbox.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/ui/components/command.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/ui/components/dialog.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/ui/components/dropdown-menu.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/ui/components/form.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/ui/components/navigation-menu.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/ui/components/popover.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/ui/components/radio-group.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/ui/components/scroll-area.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/ui/components/select.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/ui/components/separator.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/ui/components/switch.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/ui/components/tabs.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/ui/components/toggle.tsx");
